import React, { Component } from "react";
import environment from '../../environments/environments';
import { withRouter } from 'next/router'
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Col,
} from "reactstrap";
import Spinner from "../../components/shared/spinner";
import LoadingOverlay from 'react-loading-overlay'
import * as functions from '../../components/shared/functions';

class Transfer extends Component {
    AK_CITADU_PUBLIC = environment.AK_CITADU_PUBLIC;
    API_URI = environment.API_MSSR;
    PAGE_FINISH_URL = environment.pageFinish;
    ANDROID_URL = environment.Android;
    IOS_URL = environment.iOS;
    componentes = {
        content: (<div className="d-block vh-100 w-100"></div>),
    };
    constructor(props) {
        super(props);
        this.state = {
            rut: "",
            tipoCuenta: "",
            cat: "",
            config: "",
            email: "",
            titular: "",
            nroCuenta: "",
            banco: "",
            monto: 0,
            currency: "",
            loading: true,
            reload: false,
            os: ""
        };
        this.getBank = this.getBank.bind(this);
    }

    componentDidMount(props) {
        this.getBank();
    }

    /*!=======================================================
    * Nombre: getBank()
    * Descripción: Obtiene los datos de la cuenta bancaria.
    =========================================================*/
    async getBank() {
        this.getOS();
        const detalle = JSON.parse(localStorage.getItem('detail'));
        const producto = JSON.parse(localStorage.getItem('table'));
        if (!producto && !detalle) { return this.props.history.replace({ pathname: `/getmembership` }); }

        producto.forEach(data => {
            this.setState({ monto: detalle.amt, currency: data.currency })
        })
        const body = await fetch(`${this.API_URI}/checkout/bank`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json, text/plain, */*', 
                'x-api-key': this.AK_CITADU_PUBLIC,
            }
        }).then(function (res) {
            return res.json();
        }).then(function (data) {
            let body = {};
            if (data.statusCode === "200") {
                body = data.body;
                return body;
            } else {
                return body;
            }
        }).catch(err => console.log('#Error#', err))

        if (body) {
            this.setState({
                rut: body.rut,
                tipoCuenta: body.tipoCuenta,
                cat: body.cat,
                config: body.config,
                email: body.email,
                titular: body.titular,
                nroCuenta: body.nroCuenta,
                banco: body.banco,
                loading: false,
                reload: true
            });
            this.componentes.active = true;
        }
    }

    getOS() {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;
        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
            os = 'Linux';
        }
        this.setState({ os: os, loading: false, reload: true });
    }

    cerrar = () => {
        if (this.state.os === 'iOS') {
            window.location.href = this.IOS_URL;
        }
        else if (this.state.os === 'Android') {
            window.location.href = this.ANDROID_URL;
        } else {
            window.location.href = this.PAGE_FINISH_URL;
        }
        localStorage.clear();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.reload) { return true; }
        else return false;
    }

    render() {
        this.componentes.content = (this.state.rut !== "") ? (
            <div className="img-ctd" id="transfer">
                <Col className="mx-auto ml-auto mr-auto text-transfer" lg="4" md="6" sm="12">
                    <Card className="card-profile profile-bg ">
                        <CardBody>
                            <CardTitle className="title" tag="h1">Transferencia</CardTitle>
                            <p className="h4 my-0">{this.state.banco}</p>
                            <p className="h4 my-0">A Nombre de: {this.state.titular}</p>
                            <p className="h4 my-0">{this.state.tipoCuenta} {this.state.nroCuenta}</p>
                            <p className="h4 my-0">Rut: {this.state.rut}</p>
                            <p className="h4 my-0">Valor a transferir</p>
                            <h1 className="h2">{this.state.currency} {functions.formatNumber.formatear(this.state.monto)}</h1>
                            <p className="h4">Incluye en el mensaje de la transferencia el nro telefonico o envianos el comprobante a {this.state.email}</p>
                        </CardBody>
                        <CardFooter className="d-print-none">
                            <div className="follow float-center">
                                <Button className="mr-0 btn-grad-to w-100" color="primary"
                                    size="sm"
                                    onClick={() => this.cerrar()}
                                >
                                    Cerrar<i className="tim-icons icon-minimal-right" />
                                </Button>
                            </div>
                        </CardFooter>
                    </Card>
                </Col>
            </div>
        ) : null;

        return (
            <>
                <LoadingOverlay active={this.state.loading} spinner={<Spinner />} >
                    {this.componentes.content}
                </LoadingOverlay>
            </>
        );
    }
}

export default withRouter(Transfer);