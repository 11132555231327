import React, { Component } from "react";
import classnames from "classnames";
import * as $ from 'jquery';
import * as functions from '../../components/shared/functions';
import environment from '../../environments/environments';
import Spinner from "../../components/shared/spinner";
import LoadingOverlay from 'react-loading-overlay'
import { Card, CardBody, Form, Button, Input, InputGroupAddon, InputGroup, Media, Container, Row, Table, Col, } from "reactstrap";
import { withRouter } from 'next/router'
import { Alert } from "reactstrap";

class Checkout extends Component {
    API_URI = environment.API_MSSR;
    AK_CITADU_PUBLIC = environment.AK_CITADU_PUBLIC;
    clientId = environment.clientId;
    componentes = {
        content: (<div className="d-block vh-100 w-100"></div>),
        alert: (<div></div>)
    };
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            detail: [],
            dataphone: [],
            exchanges: "",
            categoria: props.match.params.categoria,
            duracion: props.match.params.duracion,
            paymentTabs: 1,
            discountFocus: undefined,
            precio: 0,
            subTotal: 0,
            descuento: "0",
            total: 0,
            method: "",
            email: "",
            nombre: "",
            cupon: "",
            textCupon: "",
            message: "",
            mensajeError: "",
            loading: true,
            reload: false,
            mensajeErrorCupon: "",
            alert: true,
            inputValue: "",
            phoneNumber: "",
            sk: ""
        }
        this.getProductsLS = this.getProductsLS.bind(this, props);
    }

    getProductsLS(props) {
        const products = localStorage.getItem('table');
        const detail = JSON.parse(localStorage.getItem('detail'));
        const phoneNumber = JSON.parse(localStorage.getItem('phoneNumber'));
        let sk = sessionStorage.getItem('token');
        this.setState({
            products: JSON.parse(products),
            detail: detail,
            subTotal: detail.amt,
            total: detail.amt,
            precio: detail.amt,
            method: detail.method,
            loading: false,
            reload: true,
            total_descuento: detail.amt,
            visible: true,
            phoneNumber: phoneNumber,
            sk: sk
        });
        if (!this.state.categoria || !this.state.duracion || this.state.duracion === "undefined" || this.state.categoria === "undefined") {
            props.history.replace({ pathname: `/getmembership/${phoneNumber}` });
        }
    }

    componentDidMount() {
        this.getProductsLS();
    }

    setDatosContacto = (d, e) => {
        switch (d) {
            case "nombre":
                this.setState({ nombre: e.target.value, reload: false });
                break;
            case "email":
                this.setState({ email: e.target.value, reload: false });
                break;
            default:
        }
    }

    cupon = (e) => {
        document.getElementById('buttonDescuento').disabled = false;
        this.setState({ textCupon: e.target.value, reload: true });
    }

    /*!=======================================================
     * Nombre: applyDiscount
     * Descripción: Verifica cupon y aplica descuento
     =========================================================*/
    applyDiscount = async (e) => {
        let codigoDescuento = document.getElementById("codigoDescuento").value;
        document.getElementById("codigoDescuento").value = "";
        if (codigoDescuento === "") {
            $(".alert-danger.cn").css("display", "block");
            this.setState({ message: "Se requiere un cod de descuento.", reload: true });
            this.setState({ loading: false, reload: true });
            return false;
        }
        this.setState({ loading: true, reload: true });
        let dct = { cod: codigoDescuento, total: this.state.total };
        const body = await fetch(`${this.API_URI}/checkout/discount`, {
            method: 'POST',
            body: JSON.stringify(dct),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': this.AK_CITADU_PUBLIC,
                'Authorization': `${this.state.sk}`
            }
        }).then(function (res) {
            return res.json();
        }).then(function (data) {
            let body = {};
            if (data.statusCode === "200") {
                body = data.body;
                return body;
            } else {
                body = data.body;
                return body;
            }
        }).catch(err => console.log('#Error#', err))
        if (body) {
            if (body.message === 'OK') {
                this.setState({ textCupon: "", cupon: codigoDescuento, inputValue: "", visible: true, descuento: body.porcentaje, total_descuento: body.total, message: body.message, loading: false, reload: true });
            } else {
                this.setState({ textCupon: "", inputValue: "", cupon: "", descuento: 0, total_descuento: this.state.total, message: body.message, loading: false, reload: true });
            }
        }
    }

    onDismiss = () => {
        this.setState({ inputValue: "", message: "", cupon: "", descuento: 0, total_descuento: this.state.total, visible: false, loading: false, reload: true });
    };

    ContinuarCompra = (props, e) => {
        document.getElementById("codigoDescuento").value = "";
        this.setState({ loading: true, reload: true });
        const phoneNumber = JSON.parse(localStorage.getItem('phoneNumber'));

        let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if (this.state.nombre === "" || this.state.email === "") {
            $(".alert-danger.cn").css("display", "block");
            this.setState({ mensajeError: "Debe proporcionar un nombre y un email de contacto.", reload: true });
            this.setState({ loading: false, reload: true });
            return false;
        }
        if (emailRegex.test(this.state.email) === false) {
            $(".alert-danger.cn").css("display", "block");
            this.setState({ mensajeError: "Email inválido.", reload: true });
            this.setState({ loading: false, reload: true });
            return false;
        }

        let contact = {};
        contact['nombre'] = this.state.nombre;
        contact['email'] = this.state.email;
        localStorage.setItem('contact', JSON.stringify(contact));
        global.total_descuento = this.state.total_descuento;
        this.setState({ mensajeError: "", reload: false });
        let cat = this.state.categoria;
        let duracion = this.state.duracion;

        localStorage.setItem('categoria', JSON.stringify(cat));
        localStorage.setItem('duracion', JSON.stringify(duracion));
        let method = this.state.method;

        if (this.state.method === "transbank") {
            let arr = {};
            this.state.products.forEach(data => {
                arr['email'] = this.state.email;
                arr['nombre'] = this.state.nombre;
                arr['descuento'] = this.state.descuento;
                arr['total'] = this.state.total;
                arr['client_id'] = phoneNumber;
                arr['cupon'] = this.state.cupon;
                arr['productos'] = [{
                    "duracion": this.state.duracion,
                    "currency": data.currency,
                    "categoria": functions.capitalizarPrimeraLetra(cat),
                    "cantidad": 1
                }]
            });
            //Crear orden de pago transbank
            return fetch(`${this.API_URI}/checkout/order/tbk`, {
                method: 'POST',
                body: JSON.stringify(arr),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-key': this.AK_CITADU_PUBLIC,
                    'Authorization': `${this.state.sk}`
                }
            }).then(function (res) {
                return res.json();
            }).then(function (data) {
                if (data.statusCode === "200") {
                    global.orden = data.body;
                    global.method = method;
                    props.history.replace({ pathname: `/shopping/order/${cat}/${duracion}` });
                }
            }).catch(err => {
                this.setState({ loading: false, reload: true })
                console.log('#Error#', err)
            })
        }
        //Pago paypal
        if (this.state.method === "paypal") {
            global.method = method;
            global.stateCheck = this.state;
            props.history.replace({ pathname: `/shopping/order/${cat}/${duracion}` });
        }

    }

    defaultAlert = () => {
        this.setState({ alert: false });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.reload) { return true; }
        else return false;
    }

    render() {
        let dataTable = [];
        let cpagoContinuarCompra = [];
        let estado = this.state;
        let msg = (this.state.message !== "OK") ? (<div className="text-danger">{this.state.message}</div>) : <div className="text-success"> Descuento aplicado!</div>;
        this.componentes.alert = (this.state.message === "OK") ? (
            <div className="mx-auto">
                <Alert color="alert" isOpen={this.state.visible} className="" toggle={this.onDismiss}>
                    <span>{this.state.cupon}</span>
                </Alert>
            </div>
        ) : null;
        this.state.products.map((data, i) => {
            cpagoContinuarCompra.push(
                <Card key={i}>
                    <CardBody>
                        <Form className="code-validate">
                            <label>Descuento</label>
                            <InputGroup
                                className={classnames({
                                    "input-group-focus": this.state.discountFocus,
                                })}
                            >
                                <Input
                                    id="codigoDescuento"
                                    className="fc-val"
                                    aria-label="Discount"
                                    name="name"
                                    placeholder="Código Descuento"
                                    type="text"
                                    onKeyPress={(e) => this.cupon(e)}
                                />
                                <InputGroupAddon addonType="append">
                                    <Button id="buttonDescuento" onClick={(e) => this.applyDiscount(e)} className="btn-grad" color="primary" type="button" disabled={!this.state.textCupon}>
                                        Aplicar
                          </Button>
                                </InputGroupAddon>
                            </InputGroup>
                            {this.componentes.alert}
                            {msg}
                        </Form>

                        <hr className="line-warning mb-3" />
                        <Media className="align-items-center">
                            <h3 className="h6 text-secondary mr-3">Subtotal</h3>
                            <Media body className="text-right">
                                <span className="color-mssr">{data.currency} {functions.formatNumber.formatear(estado.subTotal)}</span>
                            </Media>
                        </Media>
                        <Media className="align-items-center">
                            <h3 className="h6 text-secondary mr-3">Descuento</h3>
                            <Media body className="text-right">
                                <span className="color-mssr">{estado.descuento} %</span>
                            </Media>
                        </Media>
                        <hr className="line-warning mb-3" />
                        <Media className="align-items-center">
                            <h3 className="h6">Total</h3>
                            <Media body className="text-right">
                                <span className="font-weight-semi-bold color-mssr">{data.currency} {functions.formatNumber.formatear(estado.total_descuento)}</span>
                            </Media>
                        </Media>
                        <hr className="line-warning mb-3" />
                        <h4 className="mb-1">Datos de contacto:</h4>
                        <Form className="form ">
                            <div className="form-group">
                                <Input className="contact"
                                    placeholder="Nombre"
                                    type="text"
                                    onBlur={(e) => this.setDatosContacto('nombre', e)}
                                />
                            </div>
                            <div className="form-group">
                                <Input type="email"
                                    onBlur={(e) => this.setDatosContacto('email', e)}
                                    className="contact form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Email" />
                            </div>
                            <div className="text-danger">
                                {this.state.mensajeError}
                            </div>
                        </Form>
                        <Button onClick={(e) => this.ContinuarCompra(this.props, e)} className="mr-0 btn-grad w-100" color="primary">
                            Continuar
                        </Button>
                        <h5 className="mb-1 text-center"><i className="tim-icons icon-lock-circle" />Pago 100% seguro</h5>
                    </CardBody>
                </Card>
            );
            let img_cat = estado.categoria.toLowerCase();
            dataTable.push(
                <tbody key={i}>
                    <tr>
                        <td>
                            <div className="img-container">
                                <img
                                    alt="..."
                                    src={require(`assets/img/planes/p_${img_cat}_f.png`).default}
                                />
                            </div>
                        </td>
                        <td className="td-name fc-val">
                            <a className="fc-val" href={`/getmembership/${this.state.phoneNumber}`}>
                                Categoría {data.product}
                            </a>
                            <br />
                            <small className="mr-auto">{this.state.duracion}ia/s </small>
                        </td>
                        <td className="td-number">
                            1
                        </td>
                        <td className="td-number">
                            <small>{data.currency}</small>
                            {functions.formatNumber.formatear(estado.precio)}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" />
                        <td className="td-total">Total</td>
                        <td className="td-price">
                            <small>{data.currency}/ </small>
                            {functions.formatNumber.formatear(estado.precio)}
                        </td>
                    </tr>
                </tbody>
            )
        });

        this.componentes.content = (this.state.products !== "") ? (
            <div className="checkout-page img-ctd" id="checkout" >
                <div className="section" key={1}>
                    <Container>
                        <Row>
                            <Col lg="8">
                                <Form className="js-validate">
                                    <h3 className="title mb-0">Resumen de pedido</h3>
                                    <div className="cd-section" id="contentAreas">
                                        {/**/}
                                        <div id="tables">
                                            <Row>
                                                <Col md="12">
                                                    <Card className="card-plain ">
                                                        <CardBody>
                                                            <Table className="tablesorter table-shopping" responsive>
                                                                <thead>
                                                                    <tr>
                                                                        <th className="text-center" />
                                                                        <th>Producto</th>
                                                                        <th className="text-right">Qty</th>
                                                                        <th className="text-right">Monto</th>
                                                                    </tr>
                                                                </thead>
                                                                {dataTable}
                                                            </Table>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>
                                        {/* end*/}
                                    </div>
                                </Form>
                            </Col>
                            <Col lg="4">
                                <h3 className="title">Pagar</h3>
                                {/* Inicio boton Componente pago ContinuarCompra */}
                                {cpagoContinuarCompra}
                                {/* Fin boton Componente pago ContinuarCompra */}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>) : null;

        return (
            <>
                <LoadingOverlay active={this.state.loading} spinner={<Spinner />} >
                    {this.componentes.content}
                </LoadingOverlay>
            </>
        )
    }
}
export default withRouter(Checkout);