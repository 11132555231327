import React, { Component } from "react";
import Spinner from "../../components/shared/spinner";
import LoadingOverlay from 'react-loading-overlay'
import { PayPalButton } from "react-paypal-button-v2";
import * as functions from '../../components/shared/functions';
import environment from '../../environments/environments';
import Modal, { closeStyle } from 'simple-react-modal'
import {Button} from "reactstrap";
import {
    Card,
    CardBody,
    Form,
    Container,
    Row,
    Table,
    Col,
} from "reactstrap";
import { withRouter } from 'next/router'

class Shopping extends Component {
    API_URI = environment.API_MSSR;
    AK_CITADU_PUBLIC = environment.AK_CITADU_PUBLIC;
    clientId = environment.clientId;
    componentes = {
        content: (<div className="d-block vh-100 w-100"></div>),
        methodComponent: (<div></div>),
    };
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            detail: [],
            dataphone: [],
            exchanges: "",
            categoria: props.match.params.categoria,
            duracion: props.match.params.duracion,
            paymentTabs: 1,
            discountFocus: undefined,
            precio: 0,
            subTotal: 0,
            descuento: "0%",
            total: 0,
            method: "",
            cupon: "",
            shopping: {},
            phoneNumber: "",
            contact: "",
            loading: true,
            reload: false,
            transaction: {},
            show: false,
            msg: "",
            total_descuento: "",
            sk: ""
        }
        this.getProductsLS = this.getProductsLS.bind(this, props);
        this.showM = this.showM.bind(this);
    }

    componentDidMount(props) {
        this.getProductsLS();
    }

    async pagar() {
        const body = await fetch(`${this.API_URI}/payments/webpay/transaction`, {
            method: 'POST',
            body: JSON.stringify(this.state.transaction),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': this.AK_CITADU_PUBLIC,
            }
        }).then(function (res) {
            return res.json();
        }).then(function (data) {
            return data;
        }).catch(err => console.log('#Error#', err))
        if (body) {
            if (body.statusCode === "200") {
                let url = body.body.url + "?token_ws=" + body.body.token;
                window.location.href = url;
            } else if (body.statusCode === "420") {
                this.setState({ msg: body.body })
                this.showM();
            } else {
                this.setState({ msg: "Error Inesperado" })
                this.showM();
            }
        } else {
            this.pagar();
        }
    }

    getProductsLS(props) {
        let orden = global.orden;
        let stateCheck = global.stateCheck;
        let total_descuento = global.total_descuento;
        this.setState({ total_descuento: total_descuento });
        const products = JSON.parse(localStorage.getItem('table'));
        const detail = JSON.parse(localStorage.getItem('detail'));
        const phoneNumber = JSON.parse(localStorage.getItem('phoneNumber'));
        let sk = sessionStorage.getItem('token');
        this.setState({
            products: products,
            detail: detail,
            subTotal: detail.amt,
            precio: detail.amt,
            method: detail.method,
            loading: false,
            reload: true,
            sk: sk
        });

        if (global.orden && global.method === "transbank") {
            let transaction = {}
            transaction['r_cod'] = orden.r_cod;
            transaction['r_dte'] = orden.r_dte;
            transaction['r_trx'] = orden.r_trx;
            this.setState({ transaction: transaction, total: orden.total_clp, reload: true });
        }

        if (global.method === "paypal") {
            let cupon = (global.stateCheck) ? stateCheck.cupon : "";
            if (global.stateCheck) {
                this.setState({ total: stateCheck.total, cupon: stateCheck.cupon, descuento: stateCheck.descuento });
            }
            const contact = JSON.parse(localStorage.getItem('contact'));
            this.setState({ phoneNumber: phoneNumber, contact: contact });

            products.forEach(data => {
                let shopping = { "categoria": data.product, "currency": data.currency, "duracion": this.state.duracion, "cupon": cupon };
                this.setState({ shopping: shopping });
            });
        }

        if (!global.orden && !global.method) {
            this.props.history.replace({ pathname: `/checkout/${this.state.categoria}/${this.state.duracion}` });
        }

        if (global.method === "transbank") {
            this.componentes.methodComponent = (
                <div id="TransbankPay" key={2}>
                    <div className="card w-70 d-block mx-auto">
                        <img className="img-tranbk"
                            alt="..."
                            src={require("assets/img/payment-image/transbank/logos_tarjetas_webpay_down.png").default}
                        />
                    </div>
                    <div className="mt-3" >
                        <Button onClick={(e) => this.pagar(e)} className="mr-0 btn-grad w-100" color="primary">
                            PAGAR (CLP {functions.formatNumber.formatear(orden.total_clp)})
                  </Button>
                    </div>
                </div>
            );
            this.setState({ reload: true });
        } else {
            this.componentes.methodComponent = (
                <div>
                    <PayPalButton
                        options={{
                            clientId: this.clientId,
                            currency: "USD",
                        }}
                        disableCard
                        style={{
                            shape: 'rect',
                            color: 'gold',
                            layout: 'vertical',
                            label: 'pay'
                        }}

                        createOrder={(data, actions) => {
                            this.componentes.loading = false;
                            return fetch(`${this.API_URI}/checkout/order/paypal`, {
                                method: 'POST',
                                body: JSON.stringify(this.state.shopping),
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Accept': 'application/json, text/plain, */*',
                                    'x-api-key': this.AK_CITADU_PUBLIC,
                                    'Authorization': `${this.state.sk}`
                                }
                            }).then(function (res) {
                                return res.json();
                            }).then(function (data) {
                                return data.body.value;
                            }).catch(err => console.log('#Error#', err))
                        }}

                        shippingPreference="NO_SHIPPING"

                        onSuccess={(details, data) => { this.saveTransaction(details) }}

                        onError={(err) => {
                            console.log("onError:", err);
                            this.props.history.push("/order/payment/ERROR");
                        }}

                        onCancel={(data) => { }}

                        catchError={(err) => {
                            console.log("catchError:", err)
                            this.props.history.push("/order/payment/ERROR");
                        }}
                    />
                </div>
            );
            this.setState({ reload: true });
        }

        if (!this.state.categoria || !this.state.duracion || this.state.duracion === "undefined" || this.state.categoria === "undefined") {
            this.props.history.replace({ pathname: `/getmembership/${phoneNumber}` });
        }
    }

    /*!=======================================================
     * Nombre: saveTransaction 
     * Descripción: Funciòn llamada por payPal cuando la transaccion es OK / Guarda la trasacción en BD
     =========================================================*/
    saveTransaction = async (details) => {
        this.setState({ loading: true, reload: true });
        this.componentes.loading = true;
        this.componentes.pay = true;

        if (details.status === "COMPLETED") {
            let detalle = {};
            let detail_pay = {};
            detalle['id'] = details.id;
            details.purchase_units.forEach((data) => {
                detalle['currency_code'] = data.amount.currency_code;
                detalle['value'] = data.amount.value;
                detail_pay['amt'] = data.amount.value;
                detail_pay['oc'] = details.id;
                detail_pay['email'] = this.state.contact.email;
                detail_pay['nombre'] = this.state.contact.nombre;
                detail_pay['ccy'] = data.amount.currency_code;
                detail_pay['cupon'] = this.state.cupon;
                detail_pay['client_id'] = this.state.phoneNumber.substr(1);
                detail_pay['method'] = "paypal";
                detail_pay['productos'] = [{
                    'pre_total': data.amount.value,
                    'pre_unit': data.amount.value,
                    'moneda': data.amount.currency_code,
                    "id": this.state.shopping.categoria,
                    "duracion": this.state.duracion,
                    "nombre": this.state.shopping.categoria,
                    "cantidad": 1
                }];
            })
            let prop = this.props;
            const resp = await fetch(`${this.API_URI}/payments/paypal`, {
                method: 'POST',
                body: JSON.stringify(detail_pay),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-key': this.xApikey,
                    'Authorization': `${this.state.sk}`
                }
            }).then(function (res) {
                return res.json();
            }).then(function (data) {
                return data;
            }).catch(err => console.log('ERROR', err))
            if (resp) {
                if (resp.statusCode === "200") {
                    this.setState({ loading: false, reload: true });
                    localStorage.setItem('detalle', JSON.stringify(detalle));
                    prop.history.push({ pathname: `/order/payment/${details.status}` });
                } else {
                    this.setState({ loading: false, reload: true });
                    prop.history.push("/order/payment/ERROR");
                }
            }
        }
    }

    showM() {
        this.setState({ show: true })
    }

    close() {
        this.setState({ show: false })
    }

    onDragOver = (ev) => {
        ev.preventDefault();
    }

    onDragStart = (ev, id) => {
        //console.log('dragstart:', ev.target);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.reload) { return true; }
        else return false;
    }

    render() {
        let dataTable = [];
        let pago = [];
        let estado = this.state;
        this.state.products.forEach((data) => {

            pago.push(
                <Card key="1">
                    <CardBody>
                        {/* Inicio componente de pago Transbank o Paypal*/}
                        {this.componentes.methodComponent}
                        {/* Fin componente de pago Transbank o Paypal*/}
                    </CardBody>
                </Card>
            );
            let img_cat = estado.categoria.toLowerCase();
            dataTable.push(
                <tbody key="2">
                    <tr>
                        <td>
                            <div className="img-container">
                                <img
                                    alt="..."
                                    src={require(`assets/img/planes/p_${img_cat}_f.png`).default}
                                />
                            </div>
                        </td>
                        <td className="td-name fc-val">
                            <a className="fc-val" href={`/checkout/${this.state.categoria}/${this.state.duracion}`}>
                                Categoría {data.product}
                            </a>
                            <br />
                            <small className="mr-auto">{this.state.duracion}ia/s
                            </small>
                        </td>
                        <td className="td-number">
                            1
                        </td>
                        <td className="td-number">
                            <small>{data.currency}</small>
                            {functions.formatNumber.formatear(estado.total_descuento)}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" />
                        <td className="td-total">Total</td>
                        <td className="td-price">
                            <small>{data.currency}/ </small>
                            {functions.formatNumber.formatear(estado.total_descuento)}
                        </td>
                    </tr>
                </tbody>
            );
        })

        this.componentes.content = (this.componentes.methodComponent !== "") ? (
            <div className="checkout-page img-ctd" id="shopping">
                <div className="section">
                    <Container>
                        <Row>
                            <Col lg="8">
                                <Form className="js-validate">
                                    <h3 className="title mb-0">Resumen de compra</h3>
                                    <div className="cd-section" id="contentAreas">
                                        {/* tables */}
                                        <div id="tables">
                                            <Row>
                                                <Col md="12">
                                                    <Card className="card-plain">
                                                        <CardBody>
                                                            <Table className="tablesorter table-shopping" responsive>
                                                                <thead>
                                                                    <tr>
                                                                        <th className="text-center" />
                                                                        <th>Producto</th>
                                                                        <th className="text-right">Qty</th>
                                                                        <th className="text-right">Monto</th>
                                                                    </tr>
                                                                </thead>
                                                                {dataTable}
                                                            </Table>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>
                                        {/* end tables */}
                                    </div>

                                </Form>
                            </Col>
                            <Col lg="4">
                                <h3 className="title">Medio de Pago</h3>
                                {/* Inicio boton pago */}
                                {pago}
                                {/* Fin boton pago */}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        ) : null;

        return (
            <>
                <LoadingOverlay active={this.state.loading} spinner={<Spinner />} >
                    {this.componentes.content}
                    <div id="TransbankPay" onDragOver={this.onDragOver} draggable onDragStart={this.onDragStart} >
                        <div className="modalExp">
                            <Modal
                                closeOnOuterClick={true}
                                show={this.state.show}
                                onClose={this.close.bind(this)}
                                style={{ 'background': 'rgba(0, 0, 0, 0.7)' }}
                            >
                                <a style={closeStyle} onClick={this.close.bind(this)}>X</a>
                                <div id="replace-modal-header card d-block" class="modal-body">
                                    <div className="mx-auto follow float-center" lg="12" md="12" sm="12">
                                        <h4 className="text-center text-blg mb-0">{this.state.msg} !</h4>
                                    </div>
                                </div>
                                <div id="replace-modal-header card d-block" class="modal-footer">
                                    <div className="mx-auto follow float-center" lg="12" md="12" sm="12">
                                        <Button className=" mr-0 btn-grad-to w-100" color="primary"
                                            size="sm"
                                            href={`/checkout/${this.state.categoria}/${this.state.duracion}`}
                                        >
                                            Aceptar <i className="tim-icons icon-minimal-right" />
                                        </Button>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </LoadingOverlay>
            </>
        )
    }
}

export default withRouter(Shopping);