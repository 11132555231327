import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/css/nucleo-icons.css";
import "assets/scss/mssr-pays-integration-react.scss?v1.2.0";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import "assets/css/mssr.css";
import GetmemberShip from "views/components/getmembership";
import Checkout from "views/components/checkout";
import Shopping from "views/components/shopping";
import CheckoutPayment from "views/components/checkoutPayment";
import Transfer from "views/components/transfer";
import ValidateSubscription from "components/membership/ValidateSubscription";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/getmembership" render={(props) => <ValidateSubscription {...props} />} />
      <Route path="/getmembership/:phone" render={(props) => <GetmemberShip {...props} />} />
      <Route path="/checkout/:categoria/:duracion" render={(props) => <Checkout {...props} />} />
      <Route path="/shopping/order/:categoria/:duracion" render={(props) => <Shopping {...props} />} />
      <Route path="/order/payment/:session_id" render={(props) => <CheckoutPayment {...props} />} />
      <Route path="/transfer/:categoria/:duracion" render={(props) => <Transfer {...props} />} />
      <Route exact path="/" component={() => {
        window.location.href = 'https://www.citadu.com';
        return null;
      }} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);