import React, { Component } from "react";
import ValidateSubscription from "../../components/membership/ValidateSubscription";
import BuySubscription from "../../components/membership/BuySubscription"
import environment from '../../environments/environments';
import * as functions from '../../components/shared/functions';
import Spinner from "../../components/shared/spinner";
import LoadingOverlay from 'react-loading-overlay'

class GetmemberShip extends Component {
  API_URI = environment.API_MSSR;
  AK_CITADU_INFO_MSSR = environment.AK_CITADU_INFO_MSSR;
  componentes = {
    content: (<div className="d-block vh-100 w-100"></div>),
    active: false
  };
  constructor(props) {
    super(props);
    this.state = {
      dataphone: [],
      phone: ""
    };
    this.getMemberShip = this.getMemberShip.bind(this);
  }

  async getMemberShip() {
    const phone = this.props.match.params.phone;
    localStorage.setItem('phoneNumber', JSON.stringify(phone));
    const body = await fetch(`${this.API_URI}/integration/mssr/${phone}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json, text/plain, /*',
        'x-api-key': this.AK_CITADU_INFO_MSSR
      }
    }).then(function (res) {
      return res.json();
    }).then(function (data) {
      const body = {};
      if (data.statusCode === "200") {
        const rs = functions.b64_to_utf8(data.body.rs);
        body['id'] = data.body.id;
        body['rs'] = JSON.parse(rs);
        //console.log('data.body.sk;', data.body.sk);
        body['sk'] = data.body.sk;
        return body;
      } else {
        body['id'] = "";
        return body;
      }
    }).catch(err => console.log('#Error#', err))
    this.componentes.content = (body.id !== "") ? (<BuySubscription dataphone={body} />) : (<ValidateSubscription />);
    this.componentes.active = true;
    this.setState({ dataphone: body, });
  }

  componentDidMount() {
    this.getMemberShip();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.componentes.active) {
      return true;
    } else {
      return false;
    }
  }
  render() {
    return (
      <>
        <LoadingOverlay active={!this.componentes.active} spinner={<Spinner />} >
          {this.componentes.content}
        </LoadingOverlay>
      </>)
  }
}
export default GetmemberShip;