import React, { Component } from "react";
import { withRouter } from 'next/router'
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Container,
    Row,
    Col,
} from "reactstrap";
import Spinner from "../../components/shared/spinner";
import LoadingOverlay from 'react-loading-overlay'
import environment from '../../environments/environments';

class CheckoutPayment extends Component {
    PAGE_FINISH_URL = environment.pageFinish;
    ANDROID_URL = environment.Android;
    IOS_URL = environment.iOS;
    componentes = {
        content: (<div className="d-block vh-100 w-100"></div>),
    };
    anulacion = false;
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            currency_code: "",
            value: "",
            phoneNumber: 1,
            resumen: {},
            contact: {},
            method: "",
            status: props.match.params.status,
            session_id: props.match.params.session_id,
            Resumen64: props.location.search,
            loading: true,
            reload: false,
            os: ""
        }
        this.getDetalle = this.getDetalle.bind(this);
        this.getOS = this.getOS.bind(this);
    }

    componentDidMount(props) {
        this.getDetalle();
    }

    getDetalle() {
        this.componentes.active = true;
        this.getOS();
        const contact = JSON.parse(localStorage.getItem('contact'));
        const det = JSON.parse(localStorage.getItem('detail'));
        const detalle = JSON.parse(localStorage.getItem('detalle'));

        if (!contact && !det && !detalle) { return this.props.history.replace({ pathname: `/getmembership` }); }
        if (det.method === "transbank") {
            this.setState({ method: "transbank" });
            if (this.state.Resumen64) {
                let encoded_data = this.state.Resumen64.split('Rs')[1];
                let decoded_data = atob(encoded_data);
                let resumen = JSON.parse(decoded_data);
                this.setState({ resumen: resumen });
            }
            if (this.state.session_id && !this.state.Resumen64) {
                this.anulacion = true;
                const categoria = JSON.parse(localStorage.getItem('categoria'));
                const duracion = JSON.parse(localStorage.getItem('duracion'));
                this.props.history.replace({ pathname: `/checkout/${categoria}/${duracion}` });
            }
        }

        if (det.method === "paypal") {
            if (detalle) {
                this.anulacion = false;
                this.setState({ id: detalle.id, currency_code: detalle.currency_code, value: detalle.value, });
            }
            if (this.props.session_id === "ERROR") {
                this.anulacion = false;
                this.setState({ session_id: 'ERROR' });
            }
        }

        const phoneNumber = localStorage.getItem('phoneNumber');
        this.setState({
            phoneNumber: phoneNumber, contact: contact, loading: false,
            reload: true
        });
    }

    getOS() {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;
        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
            os = 'Linux';
        }
        this.setState({ os: os });
    }

    finalizar = () => {
        if (this.state.os === 'iOS') {
            window.location.href = this.IOS_URL;
        }
        else if (this.state.os === 'Android') {
            window.location.href = this.ANDROID_URL;
        } else {
            window.location.href = this.PAGE_FINISH_URL;
        }
        localStorage.clear();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.reload) { return true; }
        else return false;
    }

    render() {
        let detalle = [];
        /*********** resumen.status == 'OK' => transbank ********/
        if (this.state.resumen.status === 'OK') {
            detalle.push(
                <CardBody key={1}>
                    <div className="mx-auto">
                        <img className="mb-2 w-25 h-25"
                            alt="Logo"
                            src={require("assets/img/payment-image/transbank/comprobado.svg").default}
                        />
                    </div>
                    <h4 className="pMsg mb-0">Pedido#{this.state.resumen.oc}</h4>
                    <h4 className="pMsg mb-0">Gracias {this.state.resumen.name} !</h4>
                    <Card className="pMsg mb-1 mt-1 card-coin card-plain">
                        <h4 className="mb-0">Tu pedido está confirmado</h4>
                        <h5 className="paymentMsg">Recibirás en breve un correo electrónico de confirmación!</h5>
                    </Card>
                    <Card className="pMsg mb-1 mt-2 card-coin card-plain">
                        <div className="text-left w-0">
                            <Row className="m-2">
                                <Col lg="7" md="7" sm="7">
                                    <h4 className="mb-0">Información del cliente</h4>
                                    <h5 className="mb-0">Información de contacto</h5>
                                    <h5 className="mb-0 text-muted">{this.state.resumen.email}</h5>
                                </Col>
                                <Col lg="5" md="5" sm="5">
                                    <h4 className="mb-0">Pago</h4>
                                    <h5 className="mb-0"> <i className="icon-credit-card text-center tim-icons icon-credit-card" />{new Intl.NumberFormat("en-US", { style: "currency", currency: "CLP" }).format(this.state.resumen.amt).replace(/ /, ' ')}</h5>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                    <CardTitle tag="h3"></CardTitle>
                </CardBody>)
        }
        /*********** resumen.status == 'NOK' => transbank ********/
        if (this.state.resumen.status === 'NOK') {
            detalle.push(
                <CardBody key={3}>
                    <div className="mx-auto">
                        <img className="mb-2 w-25 h-25"
                            alt="Logo"
                            src={require("assets/img/payment-image/transbank/rechazado.svg").default}
                        />
                    </div>
                    <h4 className="pMsg mb-0">Pedido#{this.state.resumen.oc}</h4>
                    <h4 className="pMsg mb-0">Su pago no ha podido realizarse.</h4>
                    <h5 className="pMsg paymentMsg">{decodeURIComponent(escape(this.state.resumen.msg))}</h5>
                </CardBody>)
        }
        /*********** ********/
        if (!this.state.resumen.status && !this.state.session_id) {
            detalle.push(
                <CardBody key={4}>
                    <div className="mx-auto">
                        <img className="mb-2 w-25 h-25"
                            alt="Logo"
                            src={require("assets/img/payment-image/transbank/rechazado.svg").default}
                        />
                    </div>
                    <h4 className="pMsg mb-0">Su pago no ha podido realizarse.</h4>
                    <h5 className="pMsg ">{this.state.resumen.msg}</h5>
                </CardBody>)
        }
        /*********** = 'ERROR' => PAYPAL********/
        if (this.state.session_id === 'ERROR') {
            detalle.push(
                <CardBody key={5}>
                    <div className="pMsg mx-auto">
                        <img className="mb-2 w-25 h-25"
                            alt="Logo"
                            src={require("assets/img/payment-image/transbank/rechazado.svg").default}
                        />
                    </div>
                    <h4 className="pMsg mb-0">Su pago no ha podido realizarse.</h4>
                    <h5 className="pMsg ">"Ha ocurrido un error al procesar su pago."</h5>
                </CardBody>)
        }
        /*********** = 'COMPLETED' => PAYPAL********/
        if (this.state.session_id === 'COMPLETED') {
            detalle.push(
                <CardBody key={6}>
                    <div className="mx-auto">
                        <img className="mb-2 w-25 h-25"
                            alt="Logo"
                            src={require("assets/img/payment-image/transbank/comprobado.svg").default}
                        />
                    </div>
                    <h4 className="pMsg mb-0">Pedido#{this.state.id}</h4>
                    <h4 className="pMsg mb-0">Gracias {this.state.contact.nombre}!</h4>
                    <Card className="pMsg mb-1 mt-1 card-coin card-plain">
                        <h4 className="mb-0">Tu pedido está confirmado</h4>
                        <h5 className="paymentMsg">Recibirás en breve un correo electrónico de confirmación!</h5>
                    </Card>
                    <Card className="pMsg mb-1 mt-2 card-coin card-plain">
                        <div className="text-left">
                            <Row className="m-2">
                                <Col lg="7" md="7" sm="7">
                                    <h4 className="mb-0">Información del cliente</h4>
                                    <h5 className="mb-0">Información de contacto</h5>
                                    <h5 className="mb-0 text-muted">{this.state.contact.email}</h5>
                                </Col>
                                <Col lg="5" md="5" sm="5">
                                    <h4 className="mb-0">Pago</h4>
                                    <h4 className="mb-0"> <i className="icon-credit-card text-center tim-icons icon-credit-card" />{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(this.state.value).replace(/ /, ' ')}</h4>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                    <CardTitle tag="h3"></CardTitle>
                </CardBody>)
        }

        this.componentes.content = (detalle !== "") ? (
            <div className="invoice-page img-ctd" id="checkoutPayment">
                <div className="section">
                    <Container>
                        <Row>
                            <Col className="chkp mx-auto ml-auto mr-auto" lg="5" md="6" sm="12">
                                <Card className="card-profile profile-bg">
                                    {/* Inicio detalle transaction*/}
                                    {detalle}
                                    {/* Fin  detalle transaction*/}
                                    <CardFooter className="d-print-none pt-0">
                                        <div className="follow float-center">
                                            <Button className="mr-0 btn-grad-to w-100" color="primary"
                                                size="sm"
                                                onClick={() => this.finalizar()}>
                                                Finalizar <i className="tim-icons icon-minimal-right" />
                                            </Button>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        ) : null;
        return (
            <>
                <LoadingOverlay active={this.state.loading} spinner={<Spinner />} >
                    {this.componentes.content}
                </LoadingOverlay>
            </>
        )
    }
}

export default withRouter(CheckoutPayment);