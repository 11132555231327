const environment = {
  production: true,
  API_MSSR: "https://api.citadu.net/v1",
  versionApp: "1.0.0",
  AK_CITADU_INFO_MSSR : "6LGQozlQ2YagHjKcmobsh6PCYbZ3bnyP6vvP3Qmp",
  AK_CITADU_PUBLIC: "125cHAQnnpa2cB6WAdkw22kBcaGn1wlS21f99FHV",
  clientId: "Ae97aHrFpkrhLpicpcqIzdsjCnCWMBOzyP0DpN_jKcDGrDz8gJzoKTj4HwLSm8IzopkggzYqf1SblDud",  //"Abq2M5w8y3D-M3VIAmC84ef-9HPzoGmgUZxeBNGZdhO2JDz7zdWctf7OVaMdcdO875dILuKEfL1cQy_7", 
  pageFinish: "http://www.citadu.com/",  
  Android: "citadu://",  
  iOS: "citadu://" 
};

export default environment;