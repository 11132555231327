/*
Funciones generales de la aplicacion
*/
/*-----------------------------------------------------------------
  -- Nombre: b64_to_utf8
  -- Descripcion: Decodificar BASE64 IN UTF8.
  --Parametros: 
  -- --------------------------------------------------------------*/
  export function b64_to_utf8(str) {
    return decodeURIComponent(escape(window.atob(str)));
  }

/*-----------------------------------------------------------------
  -- Nombre: capitalizarPrimeraLetra(str)
  -- Descripcion: Retorna la primera letra mayuscula + el resto.
  --Parametros: 
  -- --------------------------------------------------------------*/
  export function capitalizarPrimeraLetra(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  
   /*-----------------------------------------------------------------
  -- Nombre: sortObject
  -- Descripcion: Sort object of objects by its key value JavaScript
  -- --------------------------------------------------------------*/
  export const sortObject = obj =>
  Object.keys(obj)
      .sort(function(a, b) {
      let dateA = parseInt(a);
      let dateB = parseInt(b);
      if (dateA < dateB) {
          return -1;
      } else if (dateA > dateB) {
          return 1;
      }
      return 0;
      }).reduce((res, key) => ((res[key] = obj[key]), res), {});

 /*------------------------------------------------------------------------
-- Nombre: formatNumber 
-- Descripcion: Formatear montos
-- ---------------------------------------------------------------------*/
export const formatNumber = {
  separador: ".", // separador para los miles
  sepDecimal: ',', // separador para los decimales
  formatear: function (num) {
    num += '';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
      splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    return splitLeft + splitRight;
  },
  new: function (num, simbol) {
    this.simbol = simbol || '';
    return this.formatear(num);
  }
}