import React, { Component } from "react";
import 'react-phone-number-input/style.css'
import ReactPhoneInput from "react-phone-input-2";
import environment from '../../environments/environments';
import { withRouter } from 'next/router'
import Spinner from "../shared/spinner";
import LoadingOverlay from 'react-loading-overlay'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Container,
  Col,
} from "reactstrap";

class ValidateSubscription extends Component {
  API_URI = environment.API_MSSR;
  componentes = { content: (<div className="d-block vh-100 w-100"></div>) };
  constructor(props) {
    super(props);
    this.state = {
      country: "",
      phone: "",
      loading: true,
      reload: false,
    };
    this.getCountry = this.getCountry.bind(this);
  }

  componentDidMount(props) {
    this.getCountry();
  }

  async getCountry() {
    await fetch('https://ipapi.co/json/', { method: "get" })
      .then((response) => response.json())
      .then((data) => {
        const country = (data.country).toLowerCase();
        this.setState({ country: country, loading: false, reload: true })
      }
      )
  }


  handleOnChange = (e) => {
    this.setState({ phone: e, loading: false, reload: true })
  };

  comprarSuscripción = () => {
    window.location.href = `/getmembership/+${this.state.phone}`;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.reload) { return true; }
    else return false;
  }

  render() {
    return (
      <>
        <LoadingOverlay active={this.state.loading} spinner={<Spinner />} >
          <div className="product-page " id="validateSubscription">
            <div className="page-header">
              <img
                alt="..."
                className="path shape"
                src={require("assets/img/shape-s.png").default}
              />
              <Container>
                <Col className="mx-auto " lg="5" md="8" sm="12">
                  <Card className="register card-login">
                    <Form action="" className="form" method="">
                      <CardHeader>
                        <CardTitle tag="h1"></CardTitle>
                      </CardHeader>
                      <CardBody>
                        <h4 className="description text-center">
                          Ingrese un Nro de Registro
                    </h4>
                        <Col className="ml-auto mr-auto " lg="10" md="10" sm="12">
                          <ReactPhoneInput
                            buttonClass
                            inputClass="input-lg fc-val "
                            country={this.state.country}
                            specialLabel=""
                            autocompleteSearch='true'
                            inputExtraProps={{
                              autoFocus: true
                            }}

                            defaultCountry={""}
                            value={this.state.phone}
                            onChange={this.handleOnChange}
                          />
                        </Col>
                        <Col className="ml-auto mr-auto " lg="10" md="10" sm="12">
                          <Button
                            block
                            className="btn-round mt-4 btn-grad"
                            color="primary"
                            size="lg"
                            onClick={() => this.comprarSuscripción()}
                          >
                            Comprar una suscripción
                    </Button>
                        </Col>
                      </CardBody>
                      <CardFooter>
                      </CardFooter>
                    </Form>
                  </Card>
                </Col>
              </Container>
            </div>
          </div>
        </LoadingOverlay>
      </>
    );
  }
}

export default withRouter(ValidateSubscription);