import React, { Component } from "react";
import Select from "react-select";
import classnames from "classnames";
import environment from '../../environments/environments';
import Spinner from "../shared/spinner";
import LoadingOverlay from 'react-loading-overlay'
import {
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
} from "reactstrap";
import * as functions from '../shared/functions';

class Products extends Component {
    API_URI = environment.API_MSSR;
    AK_CITADU_PUBLIC = environment.AK_CITADU_PUBLIC;
    dataPhone = {};
    dPhone = "";
    ccy = [];
    constructor(props) {
        super(props);
        this.state = {
            tabs: "",
            setTabs: "",
            ivTabs: 1,
            setivTabs: 1,
            products: [],
            dataphone: [],
            dataproducts: [],
            exchanges: "",
            categoria: "plus",
            duracion: "",
            precio: 0,
            value: 0,
            loading: true,
            reload: false,
            code: "USD",
            selectedValue: {}
        }
        this.getProducts = this.getProducts.bind(this, props);
        this.productLocal = this.productLocal.bind(this);
    }

    componentDidMount() {
        this.getProducts();
    }

    async getProducts(props) {
        let countryCode = props.dataphone.rs.country.countryCode;
        let token = props.dataphone.sk;
        sessionStorage.setItem('token', token);
        this.setState({ dataphone: props });
        const body = await fetch(`${this.API_URI}/products/list/all?countryCode=${countryCode}`, {
            method: 'GET',
            cache: "default",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json, text/plain, */*',
                'x-api-key': this.AK_CITADU_PUBLIC,
                'Authorization': `${token}`
            }
        }).then(function (res) {
            return res.json();
        }).then(function (data) {
            let body = {};
            if (data.statusCode === "200") {
                body = data.body;
                return body;
            } else {
                return body;
            }
        }).catch(err => console.log('#Error#', err))
        if (body.exchanges) {
            for (let k in body.exchanges) {
                const valor = body.exchanges[k];
                this.setState({ value: valor });
            }
            this.setState({
                dataproducts: JSON.parse(JSON.stringify(body.products)),
                products: JSON.parse(JSON.stringify(body.products)),
                exchanges: body.exchanges,
                loading: false,
                reload: true
            });
            this.productLocal(body);
        } else {
            this.setState({
                products: body.products, loading: false,
                reload: true
            });
        }
        if (this.state.dataphone.dataphone) {
            this.fnRender();
        }
    }

    /*!=======================================================
     * Nombre: productLocal
     * Descripción: Funcion para obtener la data en la moneda 
       local/pais por defecto y convertir.
     =========================================================*/
    productLocal(data, country) {
        let pais = (country) ? country : this.state.dataphone.dataphone.rs.country.countryCode;
        let val = (data.value) ? data.value : "";
        let exc = { label: pais, value: val };
        if (data.exchanges) {
            for (let k in data.exchanges) {
                if (k !== exc.label) continue;
                exc.value = data.exchanges[k];
            }
        }
        //Transformacion de valores, segun la moneda y valor de exchange
        if (exc.value !== "") {
            let temporal = JSON.parse(JSON.stringify(this.state.dataproducts));
            this.state.products.forEach((e, i) => {
                for (let dias in e.price) {
                    for (let dat in e.price[dias]) {
                        if (dat === "save") continue;
                        temporal[i]["price"][dias][dat] = e.price[dias][dat] * exc.value;
                        temporal[i]["currency"] = this.state.dataphone.dataphone.rs.country.currencyCode
                    }
                }
            });
            this.setState({ products: temporal, loading: false, reload: true });
        } else {
            this.setState({
                products: this.state.dataproducts,
                loading: false,
                reload: true
            });
        }
    }

    fnRender() {
        let estado = this.state
        let exchanges = estado.exchanges;
        let dataPhone = estado.dataphone.dataphone;
        let selectedValue = {};
        if (dataPhone) {
            this.dPhone = (
                <div className="title my-2" >
                    <div className="circletagx"
                        style={{
                            "margin": "auto",
                            "display": "block",
                            "width": "60px",
                            "height": "60px",
                            "background": "#E6E7ED",
                            "-moz-border-radius": "40px",
                            "-webkit-border-radius": "40px",
                            "background-image": `url(${dataPhone.rs.avatar})`,
                            "background-position": "50% 50%",
                            "background-repeat": "no-repeat",
                            "background-size": "70px"
                        }}
                        src={dataPhone.rs.avatar} ></div>

                    <h5 > {dataPhone.rs.username} ({dataPhone.rs.phoneNumber})</h5>

                </div>
            );

            let exc = { label: "", value: "" };
            for (let k in exchanges) {
                exc.value = exchanges[k];
                exc.label = k;
            }

            let crrcy = [{ label: "USD(USA)", value: "", pais: "USA", code: "USD" }];
            if ((exc.label === dataPhone.rs.country.countryCode)) {
                if (Object.keys(this.state.selectedValue).length === 0) {
                    selectedValue['label'] = dataPhone.rs.country.currencyCode + "(" + dataPhone.rs.country.countryNameEn + ")";
                    selectedValue['value'] = exc.value;
                    selectedValue['pais'] = dataPhone.rs.country.countryCode;
                    selectedValue['code'] = dataPhone.rs.country.currencyCode;
                    this.valores(selectedValue);
                }
                crrcy.push({
                    'label': dataPhone.rs.country.currencyCode + "(" + dataPhone.rs.country.countryNameEn + ")",
                    'value': exc.value,
                    'pais': dataPhone.rs.country.countryCode,
                    'code': dataPhone.rs.country.currencyCode
                })
                this.ccy = (
                    <Select key={1}
                        className="react-select react-select-info"
                        classNamePrefix="react-select"
                        options={crrcy}
                        onChange={(e) => this.selectChange(e, 'country')}
                        defaultValue={this.state.selectedValue}
                    />
                )
            }
        }
        this.setState({ loading: false, reload: true });
    }

    valores = (data) => {
        this.setState({ selectedValue: data, loading: false, reload: true });
    }

    selectChange = (data, country) => {
        this.productLocal(data, data.pais);
        this.setState({ selectedValue: data, loading: false, reload: true });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.reload) { return true; }
        else return false;
    }

    render() {
        /****************** toggleTabs Tab principal planes ******************/
        const toggleTabsCategoria = (e, stateName, index, product, i, precio) => {
            e.preventDefault();
            this.setState({ categoria: product, precio: precio });
            switch (stateName) {
                case "ivTabs":
                    this.setState({ ivTabs: index });
                    break;
                default:
            }
        };

        const toggleTabsDuracion = (e, stateName, index, product, i, precio) => {
            e.preventDefault();
            this.setState({ duracion: i, precio: precio, loading: false, reload: true });
            switch (stateName) {
                case "tabs":
                    this.setState({ tabs: index });
                    break;
                default:
            }
        };
        const setIndice = (i) => {
            this.state.tabs = i;
            this.state.setTabs = i;
            this.state.duracion = i;
        }
        /******************Función metodo de pago*****************/
        const pay = async (e, method, currency) => {
            let table;
            let detail = {};
            if (method === "paypal") {
                global.method = "paypal"
                table = this.state.dataproducts.filter((data) => (data.product.toLowerCase() === this.state.categoria.toLowerCase()));
            }
            if ((method === "transbank" || method === "transfer") && currency !== "CLP") {
                global.method = "transbank"
                await this.productLocal(this.state, '');
                table = this.state.products.filter((data) => (data.product.toLowerCase() === this.state.categoria.toLowerCase()));
            } else {
                table = this.state.products.filter((data) => (data.product.toLowerCase() === this.state.categoria.toLowerCase()));
            }
            table.forEach((data) => {
                for (let dia in data.price) {
                    if (dia !== this.state.duracion) continue;
                    detail = data.price[dia];
                    detail['method'] = method;
                }
                localStorage.setItem('table', JSON.stringify(table));
                localStorage.setItem('detail', JSON.stringify(detail));
            })
        }
        /******************product*****************/
        let tabPane = [];
        let NavItemSecundary = []; // Almacena la navegacion secundaria de cada plan
        let TabPaneSecundary = []; // Almacena detalle de cada plan
        let tab = 'tab';
        let ivTabs = 'ivTabs';
        let estado = this.state
        const ivTa = this.state.ivTabs;
        const tabs = this.state.tabs;
        const renObjNavItem = this.state.products.map(function (data, idx) {
            NavItemSecundary = [];
            TabPaneSecundary = [];
            let j = idx + 1;
            let ordered = functions.sortObject(data.price);
            var indice = 0;
            for (let i in ordered) {
                if (indice === 0 && estado.duracion === "") {
                    setIndice(i);
                }
                indice++;
                /****************** Inicio Navegación secundaria detalle dias PLAN******************/
                let amt = functions.formatNumber.formatear(ordered[i].amt);
                let old = functions.formatNumber.formatear(ordered[i].old);
                NavItemSecundary.push(
                    <NavItem className="nav-linkpay" key={i}>
                        <NavLink className={classnames({ active: tabs === i, })}
                            onClick={(e) => toggleTabsDuracion(e, "tabs", i, data.product, i, amt)}>
                            {i}
                        </NavLink>
                    </NavItem>
                );
                /******************* Fin Navegación secundaria detalle DIAS PLAN ******************/

                /****************** Inicio TabPane  Navegación secundaria detalle DIAS PLAN ******************/
                tab = 'tab' + i;
                TabPaneSecundary.push(
                    <TabPane tabId={tab} key={tab}>
                        <Card className="card-pricing card-plain mt-2">
                            <CardTitle className="my-0" tag="h5">{data.currency} {amt}</CardTitle>
                            <ul className="mt-0 mb-0">
                                <li >
                                    <del > {data.currency} {old}</del>
                                </li>
                                <li>
                                    {ordered[i].save} Descuento
                                 </li>
                            </ul>
                        </Card>
                    </TabPane>
                );
                /****************** Fin TabPane Navegación secundaria detalle DIAS PLAN ******************/
            }
            const newdesc = data.description.replace(/\n/g, '<br />');
            /*****************Inicio TabPane donde se pinta todo el detalle de cada plan NAVEGACION PRINCIPAL******************/
            ivTabs = 'ivTabs' + j;
            tabPane.push(
                <TabPane tabId={ivTabs} key={ivTabs}>
                    <Row>
                        <Col className="ml-auto mr-auto " lg="10" md="10" sm="12">
                            <h3 className="title-cat mb-1 text-center h4">Citadu {estado.categoria.toUpperCase()}</h3>
                            {/****************** Inicio description ******************/}
                            <h5 className="description mb-1" dangerouslySetInnerHTML={{ __html: newdesc }}></h5>
                            {/****************** Fin description ******************/}
                            <div className="title text-left my-1">
                                <h3 className="title-cat text-center mb-1 h4">Duración</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="ml-auto mr-auto" lg="10" md="10" sm="12">
                            <Card className="card-coin card-plain mt-0">
                                <CardBody>
                                    {/****************** Inicio Navegación secundaria ******************/}
                                    <Nav className="nav-tabs-primary justify-content-center px-0" tabs>
                                        {NavItemSecundary}
                                    </Nav>
                                    {/****************** Fin Navegación secundaria ******************/}
                                    {/****Inicio TabPane  Navegación secundaria detalle DIAS PLAN *****/}
                                    <TabContent className="tab-subcategories mt-2" activeTab={"tab" + tabs}>
                                        {TabPaneSecundary}
                                    </TabContent>
                                    {/****Fin TabPane  Navegación secundaria detalle DIAS PLAN *****/}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {/******************Inicio Método de Pago********************/}
                    <Row >
                        <Col className="ml-auto mr-auto" lg="10" sm="12" md="10">
                            <div className="title text-left my-1">
                                <h3 className="mb-1 title-cat text-center h4">Método de Pago</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col className="ml-auto mr-auto " lg="10" md="10" sm="12">
                            <Row className="text-center" >
                                <Col className="text-center" lg="6" md="6" sm="12">
                                    <Button onClick={(e) => pay(e, 'paypal', '')} href={`/checkout/${estado.categoria}/${estado.duracion}`} className="ml-0 btn-grad w-100" color="primary" >
                                        Paypal <i className="tim-icons icon-money-coins"></i>
                                    </Button>
                                    <p></p>
                                </Col>
                                <Col className="text-center" lg="6" md="6" sm="12">
                                    <Button onClick={(e) => pay(e, 'transbank', data.currency)} href={`/checkout/${estado.categoria}/${estado.duracion}`} className="mr-0 btn-grad w-100" color="primary">
                                        Transbank <i className="tim-icons icon-bank" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="text-center mt-3">
                                <Col className="ml-auto mr-auto " md="8">
                                    <Button onClick={(e) => pay(e, 'transfer', data.currency)} href={`/transfer/${estado.categoria}/${estado.duracion}`} className="btn-grad w-100" color="primary">
                                        Transferencia <i className="tim-icons icon-bank" />
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/******************Fin Método de Pago******************/}
                </TabPane>
            );
            /*****************Fin TabPane donde se pinta todo el detalle de cada plan NAVEGACION PRINCIPAL******************/
            let nameicon = data.product.toLowerCase();
            /****************** Navegación principal ejemplo PLUS , TEAM , VIP ******************/
            return (
                <NavItem className="nav-item-plan bcplan" key={j}>
                    <NavLink className={classnames({ active: ivTa === j })} onClick={(e) => toggleTabsCategoria(e, "ivTabs", j, data.product)} href="#">
                        <img src={require(`assets/img/planes/p_${nameicon}_f.png`).default} alt="..." className="img-plan"></img>
                    </NavLink>
                </NavItem>
            );
        });

        /***************** Integrando toda la pantalla******************/
        let activeTabivTabs = this.state.ivTabs;
        return (
            <>
                <LoadingOverlay active={this.state.loading} spinner={<Spinner />} >
                    <div className="product-page" id="buysuscription">
                        <div className="section section-pills justify-content-center">
                            <img
                                alt="..."
                                className="path shape"
                                src={require("assets/img/background/shape-s.png").default}
                            />
                            <Container>
                                <div id="navigation-pills">
                                    <Row>
                                        <Col className="ml-auto mr-auto" lg="7" md="10" sm="12">
                                            <Card className="register">
                                                <CardBody>
                                                    {/*Inicio Renderiza la lista de productos */}
                                                    <Row className="text-center" key={activeTabivTabs}>
                                                        {/*Inicio Renderiza el numero de Nombre/Telefono*/}
                                                        <Col lg="5" md="4" sm="12">
                                                            {this.dPhone}
                                                        </Col>
                                                        {/*Fin Renderiza el numero de Nombre/Telefono*/}
                                                        {/*Inicio Renderiza lista moneda*/}
                                                        <Col lg="7" md="8" sm="12">
                                                            <Row>
                                                                <Col lg="6" md="6" sm="12">
                                                                    <div className="text-center title my-2">
                                                                        <h5 className="ml-0 text-md-right text-lg-right">Mostrar valores en: </h5>
                                                                    </div>
                                                                </Col>
                                                                <Col lg="6" md="6" sm="12">
                                                                    {this.ccy}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        {/*Fin Renderiza lista moneda */}
                                                    </Row>
                                                    <div className="title text-center my-1">
                                                        <h3 className="h4">Seleccione una Categoría</h3>
                                                    </div>



                                                    <Nav className="nav-pills-just-icons justify-content-center px-1" pills role="tablist">
                                                        {/*Inicio nav planes*/}
                                                        {renObjNavItem}
                                                        {/*Fin nav planes*/}
                                                    </Nav>
                                                    <Card className="card-pay">
                                                        <CardBody>
                                                            <TabContent className="justify-content-center" activeTab={"ivTabs" + activeTabivTabs} >
                                                                {/*Inicio Detalle del plan*/}
                                                                {tabPane}
                                                                {/*Fin Detalle del plan*/}
                                                            </TabContent>
                                                        </CardBody>
                                                    </Card>
                                                    {/*Fin Renderiza la lista de productos */}
                                                </CardBody>
                                                <CardFooter>
                                                </CardFooter>
                                            </Card>
                                        </Col>
                                    </Row>
                                    {/* end nav pills */}
                                </div>
                            </Container>
                        </div>
                    </div>
                </LoadingOverlay>
            </>
        )
    }
}

export default function BuySubscription(props) {
    React.useEffect(() => {
    }, []);
    return (
        <>
            <Products {...props} />
        </>
    );
}